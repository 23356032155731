<template>
  <div class="contents">
    <div class="titleBox">
      <div class="zh">
        服務記錄查詢
      </div>
      <div class="en">
        Service Record
      </div>
    </div>
    <el-card
      v-if="list.length === 0 && !isHaveData"
      class="card-list"
    >
      尚無資料
    </el-card>
    <div
      v-for="(item,index) in list"
      :key="index"
    >
      <el-card class="card">
        <div class="product-model">
          <div class="model-img">
            <img
              :src="item.ModelPicture"
              alt=""
            >
          </div>
          <div class="model">
            <h2 class="model-name">
              型號：{{ item.ModelName }}
            </h2>
            <span>製造號碼：{{ item.MFNO }}</span>
            <span>安裝日期：{{ item.InstallDate }}</span>
            <span>安裝地址：{{ item.Addr }}</span>
          </div>
          <!-- <div class="btn">
            居家環境水質分析
          </div> -->
        </div>
      </el-card>

      <!-- <el-col :xs="12" :sm="12" :md="8" v-for="(item,index) in details.Detail" :key="index"></el-col> -->

      <!-- <el-card
        v-if="item.productList.length === 0"
        class="card-list"
      >
        尚無資料
      </el-card> -->

      <el-card
        v-for="(itemX,indexX) in item.productList"
        :key="indexX"
        class="card-list"
      >
        <el-row>
          <el-col
            :xs="2"
            :sm="2"
            :md="2"
          >
            <img
              src="../assets/img/icon_list1.png"
              alt=""
            >
          </el-col>
          <el-col
            :xs="22"
            :sm="22"
            :md="22"
          >
            <div class="cardTitle">
              淨水器濾心 {{ itemX.Sequence }} 號
            </div>
          </el-col>
        </el-row>
        <el-row class="row-list">
          <el-col>
            <el-row
              v-for="(itemY,indexY) in itemX.FilterChangeHistoryList"
              :key="indexY"
              class="col-filter"
            >
              <el-col
                :xs="2"
                :sm="2"
                :md="2"
              />
              <el-col
                :xs="24"
                :sm="24"
                :md="10"
              >
                {{ itemY.Mfno }}
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="8"
              >
                {{ itemY.LastChangeDate }}
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="4"
              >
                {{ itemY.Charge }}
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <Loading :show="isShowLoading" />
  </div>
</template>

<script>
import * as _ from 'lodash'
import { GetFilterChangeHistoryForDistribution } from '../api/api'
import storage from './../storage'
export default {
  components: {},
  data () {
    return {
      list: [
        {
          productList: [],
          InstallDate: '', // 安裝日期
          ModelName: '', // 型號
          ModelPicture: '', // 圖示
          Addr: '', // 安裝地址
          MFNO: '' // 製造號碼
        }
      ],
      LINEMid: '',
      userName: {},
      isHaveData: true,
      isShowLoading: true
    }
  },
  async mounted () {
    this.list = [
      // {
      //   productList: [
      //     {
      //       Sequence: '1',
      //       FilterChangeHistoryList: [{
      //         Mfno: 'A001',
      //         LastChangeDate: '2020-10-01',
      //         Charge: '100'
      //       }]
      //     }
      //   ],
      //   InstallDate: '2019-03-17', // 安裝日期
      //   ModelName: '除油煙機', // 型號
      //   ModelPicture: 'https://buy.sakura.com.tw/media/6207/P0230.png', // 圖示
      //   Addr: '420-台中市中港路1號', // 安裝地址
      //   MFNO: '1A00000999916' // 製造號碼
      // }
    ]
    await this.liffInit(process.env.VUE_APP_LIFFID_SERVICE_HISTORY)
    this.LINEMid = storage.getItem('LINEMid')
    const ProductData = await this.getUserCustProdData(this.LINEMid).catch(() => {
      this.isShowLoading = false
    });
    if (ProductData && ProductData[0]) {
      for (const item of ProductData) {
        const data = {
          InstallDate: item.InstallDate,
          ModelName: item.ModelTypeName,
          ModelPicture: item.ProdImage,
          Addr: item.Addr,
          MFNO: item.MFNO,
          productList: []
        }
        const result = await GetFilterChangeHistoryForDistribution({ ProductId: item.CustProdId }).catch(() => {
          this.isShowLoading = false
        })
        // const result = await GetFilterChangeHistoryForDistribution({ ProductId: '57cd841f-ae77-ea11-81b2-005056af7974' })
        data.productList = _.get(result, 'data.Data') || []
        this.list.push(data)
      }
    }
    if (this.list.length === 0) { this.isHaveData = false }
    this.isShowLoading = false
    console.log('isHaveData', this.isHaveData)
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.titleBox {
    text-align: center;
    color: #131313;
}
.titleBox .zh {
    font-size: 30px;
    padding: 15px 0;
}
.titleBox .en {
    font-size: 20px;
    padding: 0 0 15px 0;
}
.card {
    border-radius: 1rem;
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    .product-model {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .model-img {
        flex: 0 0 10.3rem;
        width: 10.3rem;
        margin-right: 1.5rem;
        img {
          width: 10.3rem;
        }
      }
      .model {
        display: flex;
        flex-direction: column;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        .model-name {
          line-height: 2.8rem;
          margin-bottom: .6rem;
          font-size: 16px;
          font-weight: 700;
          color: #c8161d;
        }
        span {
          line-height: 2.1rem;
          font-size: 14px;
          color: #868686;
        }

      }
      .btn {
        width: 16.8rem;
        height: 4.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        background: #c8161d;
        border-radius: .6rem;
        color: #fff;
        }
    }
}
.card-list {
    border-radius: 1rem;
    padding: 2rem 3rem;
    margin-bottom: 2rem;
    img {
      width: 12px;
    }
    .cardTitle{
      font-size: 16px;
      padding-top: 5px;
    }
}
.row-list {
    padding: 10px 0;
    font-size: 14px;
    color: #888888;
    line-height: 22px;
}
@import '../assets/styles/mixin.scss';
.contents {
  .el-card {
    border-radius: 1rem;
  }
}

@media screen and (max-width: 992px){
  .col-filter {
    border-bottom: solid 1px #909090;
  }
}

</style>
